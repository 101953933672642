import React from "react";
import PropTypes from "prop-types";
import { IMAGE_POSITIONS } from "../../utils/imagePosition";
import Tile, { TileContainer } from "../Tile";

const PageTiles = ({ pages }) => (
  <TileContainer>
    {pages.map(
      ({ id, slug, introHeaderTitle, heroImage, introHeaderSubtitle }) => (
        <Tile
          imagePosition={slug === "team" ? IMAGE_POSITIONS.TOP : ""}
          key={id}
          url={slug}
          title={introHeaderTitle}
          subtitle={introHeaderSubtitle}
          heroImage={heroImage}
        />
      )
    )}
  </TileContainer>
);

PageTiles.propTypes = {
  pages: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default PageTiles;
