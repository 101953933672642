import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { get, pipe, map } from "lodash/fp";
import ServiceTiles from "./ServiceTiles";

const query = graphql`
  query {
    services: allContentfulService {
      edges {
        service: node {
          ...service
        }
      }
    }
  }
`;

export default () => (
  <StaticQuery
    query={query}
    render={data => {
      const services = pipe(
        get("services.edges"),
        map("service")
      )(data);
      return <ServiceTiles services={services} />;
    }}
  />
);
