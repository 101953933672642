import React from "react";
import PropTypes from "prop-types";

import Tile, { TileContainer } from "../Tile";

const ServiceTiles = ({ services }) => (
  <TileContainer>
    {services.map(({ title, slug, subtitle, heroImage }) => (
      <Tile
        key={title}
        url={`services/${slug}`}
        title={title}
        subtitle={subtitle}
        heroImage={heroImage}
      />
    ))}
  </TileContainer>
);

ServiceTiles.propTypes = {
  services: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string,
      title: PropTypes.string,
      subtitle: PropTypes.string
    })
  ).isRequired
};

export default ServiceTiles;
