import React from "react";
import { StaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import Garment from "./Garment";
import { GarmentProps } from "../../proptypes";
import { TileContainer } from "../Tile";

const Garments = ({ data }) => {
  const json = data.allContentfulProduct.edges;
  return (
    <TileContainer>
      {json.map(edge => (
        <Garment key={edge.node.id} {...edge.node} />
      ))}
    </TileContainer>
  );
};

Garments.propTypes = {
  data: PropTypes.shape({
    allContentfulProduct: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          edges: PropTypes.arrayOf(
            PropTypes.shape({
              node: PropTypes.shape(GarmentProps)
            })
          )
        })
      )
    })
  }).isRequired
};

const query = graphql`
  query {
    allContentfulProduct(
      filter: { showOnHomepage: { eq: true } }
      sort: { fields: [displayOrder], order: ASC }
    ) {
      edges {
        node {
          id
          slug
          subtitle
          name
          mainImage {
            fluid(maxWidth: 500) {
              ...fluidImage
            }
          }
        }
      }
    }
  }
`;

const render = data => <Garments data={data} />;

export default () => <StaticQuery query={query} render={render} />;
