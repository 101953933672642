import React from "react";
import PropTypes from "prop-types";
import Tile, { TileContainer } from "../Tile";

const PageTiles = ({ pages }) => (
  <TileContainer>
    {pages.map(
      ({ id, heroImage, slug, introHeaderTitle, introHeaderSubtitle }) => (
        <Tile
          key={id}
          heroImage={heroImage}
          url={slug}
          title={introHeaderTitle}
          subtitle={introHeaderSubtitle}
        />
      )
    )}
  </TileContainer>
);

PageTiles.propTypes = {
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      url: PropTypes.string,
      title: PropTypes.string,
      subtitle: PropTypes.string
    })
  ).isRequired
};

export default PageTiles;
