import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Image from "gatsby-image";
import classNames from "classnames";

import { imagePositionClasses } from "../../utils/imagePosition";

import { pageTile, pageTiles, pageTileContent } from "./styles.module.scss";

const Tile = ({ heroImage, title, subtitle, url, imagePosition }) => (
  <Link to={`/${url}`} className={pageTile}>
    <div className={pageTileContent}>
      <h3>{title}</h3>
      <div>{subtitle}</div>
    </div>
    <Image
      {...heroImage}
      alt={`${title} - ${subtitle}`}
      title={subtitle}
      className={classNames(imagePositionClasses(imagePosition))}
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%"
      }}
    />
  </Link>
);

Tile.propTypes = {
  heroImage: PropTypes.shape({}).isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  imagePosition: PropTypes.string
};

Tile.defaultProps = {
  imagePosition: ""
};

export const TileContainer = ({ children }) => (
  <div className={pageTiles}>{children}</div>
);

TileContainer.propTypes = {
  children: PropTypes.node.isRequired
};

export default Tile;
