import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";

import Helmet from "react-helmet";
import SEO from "../components/SEO";
import Layout from "../layouts/index";
import IntroHeader from "../components/IntroHeader";
import Garments from "../components/Garments";
import PageTiles from "../components/PageTiles";
import BlogPageTiles from "../components/BlogPageTiles";
import ServiceTiles from "../components/ServiceTiles";
// import Promotion from "../components/Promotion";

library.add(faPhone);
library.add(faEnvelope);
library.add(faInstagram);

const HomePage = ({ data }) => (
  <Layout>
    <SEO />
    <Helmet />
    <IntroHeader {...data.contentfulPage} />
    {/* <Promotion /> */}
    <div style={{ marginBottom: 12 }} />
    <Garments />
    <PageTiles />
    <ServiceTiles />
    <BlogPageTiles />
  </Layout>
);
HomePage.propTypes = {
  data: PropTypes.shape({
    contentfulPage: PropTypes.shape({
      title: PropTypes.string
    }).isRequired
  }).isRequired
};
export default HomePage;

export const query = graphql`
  {
    contentfulPage(slug: { eq: "home" }) {
      ...page
    }
  }
`;
