import React from "react";
import { GarmentProps } from "../../proptypes";
import { IMAGE_POSITIONS } from "../../utils/imagePosition";
import Tile from "../Tile";

const Garment = ({ name, subtitle, mainImage }) => (
  <Tile
    imagePosition={IMAGE_POSITIONS.TOP}
    url="garments"
    title={name}
    subtitle={subtitle || `Browse our ${name}`}
    heroImage={mainImage}
  />
);

Garment.propTypes = GarmentProps;

export default Garment;
