import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { pipe, get, map } from "lodash/fp";
import PageTiles from "./PageTiles";

const query = graphql`
  query {
    pages: allContentfulPage(filter: { slug: { in: ["pricing", "process"] } }) {
      edges {
        node {
          id
          slug
          introHeaderTitle
          introHeaderSubtitle
          title
          heroImage {
            id
            fluid {
              ...fluidImage
            }
          }
        }
      }
    }
  }
`;

const processData = pipe(
  get("pages.edges"),
  map("node"),
  pages => ({ pages })
);

export default props => (
  <StaticQuery
    query={query}
    render={pipe(
      processData,
      data => (
        <PageTiles {...data} {...props} />
      )
    )}
  />
);
