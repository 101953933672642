import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { get } from "lodash/fp";
import PageTiles from "./PageTiles";

const query = graphql`
  query {
    latestBlogPost: allContentfulBlogPost(
      sort: { fields: [publishAt], order: DESC }
      filter: { published: { eq: true } }
      limit: 1
    ) {
      edges {
        node {
          ...blogPost
        }
      }
    }
    pages: allContentfulPage(filter: { slug: { in: ["team", "blog"] } }) {
      edges {
        node {
          id
          slug
          introHeaderTitle
          introHeaderSubtitle
          title
          heroImage {
            id
            fluid {
              ...fluidImage
            }
          }
        }
      }
    }
  }
`;

const processData = data => {
  let { pages } = data;
  const { latestBlogPost } = data;
  const postData = latestBlogPost.edges[0].node;
  pages = pages.edges.map(get("node")).map(p => {
    if (p.slug === "blog") {
      return {
        ...p,
        slug: `blog/${postData.slug}`
      };
    }
    return p;
  });
  return { ...data, pages };
};

export default () => (
  <StaticQuery
    query={query}
    render={data => <PageTiles {...processData(data)} />}
  />
);
